import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BookButtons from '../components/BookButtons'
import { Cookies } from 'react-cookie'

export const HymnTunesTemplate = ({ data }) => {
  const cookies = new Cookies()

  const bookCookie = cookies.get('book')

  return (
    <Layout>
      <section
        style={{ minHeight: '85vh' }}
        className="px-2 py-4 bg-light-grey sm:px-16"
      >
        <BookButtons />
        <h2 className="mt-8 text-center border-b">Hymntunes</h2>
        {bookCookie === 'worship' ? (
          <>
            <h4 className="mb-4 text-center sm:mt-6">
              Worship and Congregational Singing
            </h4>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">
              {data.allNamesJson.edges.map(({ node }) => {
                return (
                  <div key={node.id} className="text-center">
                    <div>
                      <p>{node.name}</p>
                      <audio
                        className="block w-24 mx-auto my-6 sm:m-6 sm:w-64 sm:mx-auto"
                        controls
                        preload="false"
                        src={node.url.publicURL}
                      >
                        <track
                          src="captions_en.vtt"
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                        Your browser doesn&#39;t support audio
                      </audio>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <>
            <h4 className="mb-4 text-center sm:mt-6">
              Volumes of Praise for a Vanishing God
            </h4>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">
              {data.book.edges.map(({ node }) => {
                return (
                  <div key={node.id} className="text-center">
                    <div>
                      <p>{node.name}</p>
                      <audio
                        className="block w-24 mx-auto my-6 sm:m-6 sm:w-64 sm:mx-auto"
                        controls
                        preload="false"
                        src={node.url.publicURL}
                      >
                        <track
                          src="captions_en.vtt"
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                        Your browser doesn&#39;t support audio
                      </audio>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </section>
    </Layout>
  )
}

export default HymnTunesTemplate

export const pageQuery = graphql`
  query HymnTunesPage {
    book: allNamesJson(
      sort: { fields: name }
      filter: { book: { eq: "book" }, relativeDirectory: { eq: "hymntunes" } }
    ) {
      edges {
        node {
          chapter
          id
          name
          url {
            publicURL
          }
        }
      }
    }
    allNamesJson(
      sort: { fields: name }
      filter: {
        book: { eq: "worship" }
        relativeDirectory: { eq: "hymntunes" }
      }
    ) {
      edges {
        node {
          chapter
          id
          name
          url {
            publicURL
          }
        }
      }
    }
  }
`
